import React, { Component } from "react"
import { Link } from "gatsby"
import logoIcon from "../../../assets/logo.png"

import style from "./index.module.css"

class Footer extends Component {
  state = {
    initialScroll: typeof window !== 'undefined' ? window.scrollY + 1 : 0,
    scrollDirection: 'up',
    justStarted: true
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }


  handleScroll = () => {
    if (this.state.justStarted) {
      this.setState({ scrollDirection: 'up', initialScroll: window.scrollY, justStarted: false })
    } else {
      if (this.state.initialScroll < window.scrollY) {
        this.setState({ scrollDirection: 'down', initialScroll: window.scrollY })
      } else {
        this.setState({ scrollDirection: 'up', initialScroll: window.scrollY })
      }
    }
  }

  render() {
    const display = this.state.scrollDirection === 'down' ? {display: 'flex'} : {display: 'none'}

    return (
      <div className={style.footerContainer} style={display}>
        <Link to={'/'}>
          <img className={style.logo} src={logoIcon} alt="logo" />
        </Link>
      </div>
    )
  }
}

export default Footer