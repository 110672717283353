import React, { Component } from "react"
import { Link } from 'gatsby'
import cameraIcon from "../../../assets/camera.png"
import computerIcon from "../../../assets/computer.png"
import contactIcon from "../../../assets/contact.png"
import infoIcon from "../../../assets/info.png"
import logoIcon from "../../../assets/logo.png"
// import facebookIcon from "../../../assets/facebook.png"
import behanceIcon from "../../../assets/behance.png"
import instagramIcon from "../../../assets/instagram.png"
import linkedinIcon from "../../../assets/linkedin.png"
import pencilIcon from "../../../assets/pencil.png"

import style from "./index.module.css"

class Header extends Component {
  state = {
    initialScroll: typeof window !== 'undefined' ? window.scrollY + 1 : 0,
    scrollDirection: "up",
    justStarted: true,
    isMobile: false
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll)
    window.scrollTo(0, 0)
    if (window.innerWidth < 650) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    if (this.state.justStarted) {
      this.setState({
        scrollDirection: "up",
        initialScroll: window.scrollY,
        justStarted: false
      })
    } else {
      if (this.state.initialScroll < window.scrollY) {
        this.setState({
          scrollDirection: "down",
          initialScroll: window.scrollY
        })
      } else {
        this.setState({
          scrollDirection: "up",
          initialScroll: window.scrollY
        })
      }
    }
  }

  render() {
    const socials = (
      <React.Fragment>
        <a
          title="Facebook"
          href="https://www.behance.net/karpasitis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={style.iconsLink} src={behanceIcon} alt="Facebook" />
        </a>
        <a
          title="Instagram"
          href="http://www.instagram.com/karpas.photography"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={style.iconsLink}
            src={instagramIcon}
            alt="Instagram"
          />
        </a>
        <a
          title="LinkedIn"
          href="https://www.linkedin.com/in/andreas-karpasitis-b5a90182/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={style.iconsLink} src={linkedinIcon} alt="LinkedIn" />
        </a>
      </React.Fragment>
    )
    const display =
      this.state.scrollDirection === "up"
        ? { display: "flex" }
        : { display: "none" }

    return (
      <React.Fragment>
        <div className={style.sideBar}>
          <div className={style.socialsSidebar}>{socials}</div>
        </div>
        <div className={style.headerContainer} style={display}>
          <div className={style.headerLeft}>
            <div className={style.headerSectionLeft}>
              <img className={style.icons} src={cameraIcon} alt="Art" />
              <Link
                to="/art"
                className={style.link}
                style={{
                  margin: this.state.isMobile ? "5px 5px" : ""
                }}
              >
                Art
              </Link>
              <img className={style.icons} src={cameraIcon} alt="Travel" />
              <Link
                to="/travel"
                className={style.link}
              >
                Travel
              </Link>
            </div>
            <div className={style.headerSection}>
              <img className={style.icons} src={computerIcon} alt="Web" />
              <Link to="/web" className={style.link}>
                Web
              </Link>
              <img className={style.icons} src={pencilIcon} alt="Web" />
              <Link to="/the-isle-of-olympia" className={style.link}>
                Book
              </Link>
            </div>
          </div>
          <div className={style.headerRight}>
            <div
              className={style.headerSection}
              style={{
                paddingRight: this.state.isMobile ? "0px" : "10px",
                margin: this.state.isMobile ? "5px 10px" : ""
              }}
            >
              <Link to="/contact">
                <img
                  title="Contact"
                  className={style.iconsLink}
                  src={contactIcon}
                  alt="Contact"
                />
              </Link>
              <Link to="/about">
                <img
                  title="About"
                  className={style.iconsLink}
                  src={infoIcon}
                  alt="About"
                />
              </Link>
            </div>
            <div
              className={style.headerSection}
              style={{
                paddingLeft: "10px"
              }}
            >
              <div className={style.socials}>{socials}</div>
              <Link to="/">
                <img className={style.logo} src={logoIcon} alt="logo" />
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Header
